<template>
  <v-text-field
    v-model="value"
    type="text"
    :label="name"
    :key="formKey"
    :rules="[
      () =>
        (!!value && value.length >= 7 && value.length <= 15) ||
        $t('rules.mobile'),
      () => Number.isInteger(Number(value)) || $t('rules.mobileNumber'),
    ]"
  ></v-text-field>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError", "value", "name", "formKey"],
  data: () => ({
    name: null,
  }),
  watch: {
    value: function (val) {
      this.callbackSelect(val);
    },
  },
};
</script>

<style>
</style>