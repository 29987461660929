<template>
  <v-text-field
  v-model="value"
    type="text"
    :label='name'
    :key= "formKey"
    :rules="rules"
  ></v-text-field>
</template>

<script>
import i18n from '@/plugins/i18n'
export default {
   props: ["callbackSelect", "callbackError","value","name","formKey"],
    data: () => ({
     rules : [v => !!v || i18n.t("rules.name")],
   
  }),
   watch: {
    value: function (val) {
      this.callbackSelect(val);
    },
  },
};
</script>

<style>
</style>