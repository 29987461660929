<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <PageEdit
      :role="role"
      :model="model"
      :url="`${$api.servers.moderator}/v1/school/${school.id}/teacher/edit`"
      action="edit"
      :self="me"
      :callbackSuccess="callbackSuccess"
    ></PageEdit>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/teacher";
//EOC
import PageEdit from "@/components/Bread/Teacher/PageEdit";
import { mapState } from "vuex";
export default {
  components: {
    PageEdit,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["self"],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[self]
    me: {
      id: null,
    },
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[guard]
    if (!this.model[this.role].edit) {
      this.$router.replace(
        this.$_getRouteRead(
          this.role,
          this.model.key,
          this.$route.params.id,
          this.self
        )
      );
      return;
    }
    //EOC
    //BOC:[self]
    if (this.self) {
      this.me = this.$_.cloneDeep(this.self);
    } else {
      this.$router.replace(
        this.$_getRouteRead(this.role, this.model.key, this.$route.params.id)
      );
    }
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text:this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.me.name
        ? this.me.name
        : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
      to: this.$_getRouteRead(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self
      ),
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("action.edit"),
      to: this.$_getRouteEdit(
        this.role,
        this.model.key,
        this.$route.params.id,
        this.self
      ),
      exact: true,
    });
    //EOC
    //BOC:[url]
    this.api.url = `${this.$api.servers.moderator}/v1/school/${this.school.id}/teacher/${this.$route.params.id}`;
    //EOC
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.me = resp;
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    callbackSuccess(resp) {
      this.$router.push(
        this.$_getRouteRead(this.role, this.model.key, resp.id, resp)
      );
    },
  },
  beforeMount() {
    this.$api.fetch(this.api);
  },
};
</script>